import React from 'react';

export type HeadingProps = {
  children: string | React.ReactElement;
};

const Heading = ({ children }: HeadingProps) => {
  return <h1 style={{ fontSize: '48px' }}>{children}</h1>;
};


export default Heading;