import React from 'react';
import { Button } from '@mui/joy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { Page } from '../../types/navigation';

const Dashboard = () => {
  return (
    <Link to={Page.CreateProject}>
      <Button
        variant='solid'
        color={'primary'}
        startDecorator={<FontAwesomeIcon icon={faPlus} />}
      >
        Create Project
      </Button>
    </Link>
  );
};

export default Dashboard;
