import React from 'react';
import { Card as JoyUICard } from '@mui/joy';

export type CardProps = {
  children: React.ReactElement;
  size?: 'sm' | 'md' | 'lg';
};

const Card = ({ children, size = 'md' }: CardProps) => {
  return <JoyUICard sx={{ boxShadow: 'sm' }} variant='plain' size={size}>{children}</JoyUICard>;
};

export default Card;
