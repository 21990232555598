import { Snackbar, Link } from '@mui/joy';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export type LoadingMessageProps = {
  isError: boolean;
  error?: unknown;
  retry?: () => void;
};

const ErrorMessage = ({ isError, error, retry }: LoadingMessageProps) => {
  return (
    <Snackbar open={isError} color='danger' size='lg'>
      <>
        <FontAwesomeIcon icon={faCircleExclamation} />
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ fontWeight: 'bold' }}>Something went wrong</div>
          <div style={{ fontSize: '14px' }}>
            {error instanceof Error ? error.message : 'Please try again'}
          </div>
          <div style={{ fontSize: '14px' }}>
            {retry ? (
              <Link onClick={retry} color='danger' sx={{ fontSize: '14px' }} >Please click here to try again</Link>
            ) : (
              <>Please try again</>
            )}
          </div>
        </div>
      </>
    </Snackbar>
  );
};

export default ErrorMessage;
