import axios from "axios";
import { getEnvVariables } from "../util/env-variables";
import { User } from "../types/user";

const userService = {
    searchForUserByEmail: async (accessToken: string, email: string): Promise<User> => {
        const { apiRootUrl } = getEnvVariables();

        const response = await axios.get(`${apiRootUrl}/user/email?email=${email}`, { headers: { Authorization: `Bearer ${accessToken}`}});

        return response.data.data as User;
    }
};

export default userService;