import axios from "axios";
import { Project, ProjectCreateInput } from "../types/project";
import { getEnvVariables } from "../util/env-variables";

const projectService = {
    createProject: async (accessToken: string, project: ProjectCreateInput): Promise<Project> => {
        try {
            const { apiRootUrl } = getEnvVariables();

            const response = await axios.post(`${apiRootUrl}/project`, project, { headers: { Authorization: `Bearer ${accessToken}`}});
    
            return response.data.data as Project;
        } catch (err) {
            return Promise.reject(err as Error);
        }
    }
};

export default projectService;