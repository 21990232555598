import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import App from './App';
import { Auth0Provider } from '@auth0/auth0-react';
import { getEnvVariables } from './util/env-variables';
import { QueryClient, QueryClientProvider } from 'react-query';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const {
  authDomain,
  authClientId,
  authAudience,
  rootUrl
} = getEnvVariables();

const queryClientProvider = new QueryClient();

root.render(
  <QueryClientProvider client={queryClientProvider}>
    <Auth0Provider
      domain={authDomain}
      clientId={authClientId}
      authorizationParams={{ redirect_uri: `${rootUrl}`, audience: authAudience }}
      cacheLocation='localstorage'
    >
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </Auth0Provider>
  </QueryClientProvider>
);
