import React from 'react';
import { User } from '../../types/user';
import { css } from 'aphrodite';
import profileNameStyles from './profile-name.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleUser } from '@fortawesome/free-solid-svg-icons';
import { useAuth0 } from '@auth0/auth0-react';

export type ProfileNameProps = {
  user: User;
  variant?: 'large' | 'small';
  tagline?: string;
  timeline?: string;
};

const ProfileName = ({
  user,
  variant = 'small',
  tagline,
  timeline,
}: ProfileNameProps) => {
  const { user: authenticatedUser } = useAuth0();

  return (
    <div className={css(profileNameStyles.container)}>
      <div
        className={css(profileNameStyles.nameAndProfileGroup)}
        style={{
          gap: variant === 'small' ? '8px' : '16px',
        }}
      >
        <div className={css(profileNameStyles.iconContainer)}>
          {user.picture ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img src={user.picture} width={'100%'} style={{ borderRadius: '50%'}} alt={user.name.full} />
            </div>
          ) : (
            <FontAwesomeIcon
              icon={faCircleUser}
              style={{ width: '100%', height: '100%' }}
            />
          )}
        </div>
        <div>
          <div
            className={css(profileNameStyles.name)}
            style={{ fontSize: tagline ? '16px' : '18px' }}
          >
            {user.name.full}
            {authenticatedUser?.sub === user.id && ' (You)'}
          </div>
          {tagline && (
            <div className={css(profileNameStyles.tagline)}>{tagline}</div>
          )}
        </div>
      </div>
      <div className={css(profileNameStyles.timeline)}>{timeline ?? ''}</div>
    </div>
  );
};

export default ProfileName;
