import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Page from './components/page/page';
import Layout from './components/layout/layout';
import Dashboard from './pages/dashboard/dashboard';
import CreateProject from './pages/create-project/create-project';
import NotFound from './pages/404/not-found';

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route index element={<Page component={<Dashboard />} pageTitle='Dashboard' />} />
          <Route path='create-project' element={<Page component={<CreateProject />} pageTitle='Create Project' />} />
          <Route path='*' element={<Page component={<NotFound />} pageTitle='Not Found' />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App;
